.tc {
  .toast {
    background-color: rgba(255, 255, 255, 0.5);
    .toast-header {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
}

.tc.error {
  .toast {
    background-color: rgba(255, 0, 0, 0.05);
    .toast-header {
      background-color: rgba(255, 0, 0, 0.025);
      .me-auto {
        color: #b20000;
      }
    }
  }
}
