.main-app-title {
  text-align: center;
  margin: 0px -12px 0px 0px;
  background-color: #00000008;
  padding: 5px 0px 10px 0px;
  border-radius: 8px;
  cursor: pointer;
  .app-title-wrap {
    margin-top: -15px;
    margin-left: -10px;
  }
  .app-icon {
    color: #bee0ec;
    font-size: 70px;
    margin-top: -15px;
    margin-right: 4px;
  }
  .app-icon-tag {
    color: "#1a6985";
    p {
      font-size: 32px;
      margin-bottom: 0px;
    }
    p.main {
      margin-top: 5px;
      font-size: 24px;
    }
    p.sec {
      margin-top: -10px;
      font-size: 22px;
    }
  }
  p.s {
    margin-bottom: 0px;
    margin-top: -13px;
    color: #1a6985;
    font-size: 14px;
  }
}

.v-l-nav {
  margin-top: 15px;
  color: #483d8b;
  .muted-category {
    font-size: 13px;
    letter-spacing: 1.25px;
    color: #999;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .category-icon {
    padding-left: 2px 4px;
    cursor: pointer;
    color: #286a5d;
  }
  .highlight-category {
    background-color: #2083a6;
    padding: 2px 4px;
    font-weight: 500;
    color: #fff;
    border-radius: 8px;
    font-size: 9px;
  }
  ul.sc {
    padding-left: 5px;
    li {
      display: block;
      a {
        display: block;
        padding: 7px 5px 7px 20px;
        text-decoration: none;
        color: inherit;
        margin: 2px 0px;
        border-radius: 50px;
        &:hover {
          background-color: #f5f4fa;
        }
      }
      a.active {
        background-color: #e5e4ea;
      }
    }
  }
}
