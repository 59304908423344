.report-date-picker .react-datepicker {
    border-radius: 200px; /* Adjust this value to increase or decrease roundness */
  }
  
  .report-date-picker .react-datepicker__header {
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
  }
  
  .report-date-picker .react-datepicker__month-container {
    border-radius: 100px;
  }
/* Application card */
.app-selected-true{
background-color: #f5f6f5!important;
}



/* Metric */
.metric-header {
  background-color: rgb(200, 216, 217);
  text-align: center;
  border-bottom: 0px solid #fff;
  border-radius: 0px;
  padding: 8px 0px;
  margin: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  letter-spacing: 4px;
  font-size: 24px;
  font-weight: normal;
  color: #333;
}

.metric-card{
  background-color:rgba(243, 248, 249, 0.499) !important;
  padding:15px;
  display: flex;
  flex-direction: column;
  /* max-width: fit-content; */
  /* justify-content: ; */
  align-items: center;
}
.metric-expand {
  width:100%;
}

/* Eyal Stuff
 offers : R196 G228 B255 
 Apps   : R200 G234 B223 
 Metrics  R149 G165 B166 
    C8D8D9       
 Metric card 
*/