.bpmnviewcontainer {
}
#bpmn-tool-bar-component {
  position: fixed;
  left: 40%;
  z-index: 2000;
  top: calc(5rem / 4);
  display: flex;
  width: 20%;
}
/* // sx={{
    
    
    // }} */
