:root {
  --cap-title-bg-color: #c9eae0;
  --cap-app-title-bg-color: #d7f0e8;
  --cap-app-container-chip-color: #e5f5f0;
  --cap-app-container-bg-color: #f7fcfb;

  --bs-accordion-bg: #e8f7ff40 !important;
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.track-vertical {
  position: absolute;
  width: 6px;
  right: 2px;
  bottom: 2px;
  top: 2px;
  border-radius: 3px;
  z-index: 2;
}

.list-group-light:first-child {
  border-radius: 0px;
}

.review-card {
  height: 250px !important;
}

h4.section-title {
  background-color: #eee;
  border-bottom: 0px solid #fff;
  border-radius: 0px;
  padding: 8px 0px;
  margin: 0px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  letter-spacing: 4px;
  font-size: 24px;
  font-weight: normal;
  color: #333;
}

/*******************************************
* Left side navnar                         *
********************************************/
.l-nav {
  background-color: #fff;
}

/*******************************************
* Right side navnar                        * 
********************************************/
.r-nav {
  position: sticky;
  top: 85px;
  width: 75%;
  color: #483d8b;
  margin-left: -15px;
  padding-left: 15px;
  border-left: 1px solid #eee;
  p {
    font-size: 14px;
    padding-bottom: 5px;
    font-weight: 500;
    border-bottom: 1px solid #eee;
  }
  a.nav-link {
    text-decoration: none;
    display: block;
    padding: 5px 10px 5px 15px;
    background-color: #fff;
    margin: 2px 0px;
    border-radius: 50px;
    color: inherit;
    &:hover {
      background-color: rgba(245 244 250 / 75%);
    }
  }
  a.nav-link.active {
    background-color: rgba(235 234 240 / 100%);
  }
}

.r-nav.pld {
  top: 0px;
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  overflow-y: auto;
  a.nav-link {
    padding: 5px 10px 5px 10px;
  }
  .sub-link {
    padding: 5px 15px 5px 10px;
    font-size: 13px;
  }
}

/*******************************************
 *            Select offer page            *
 *******************************************/

/* top navigation tabs */
ul.steps {
  border-width: 0px;
  // margin-left: -12px;
  // margin-right: -12px;
  background-color: #f2f2ff;
  border-radius: 50px;
  margin-bottom: 10px;
  .nav-item {
    margin: 0px;
    &:first-child {
      .nav-link {
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
      }
    }
    &:last-child {
      .nav-link {
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
      }
    }
    .nav-link {
      background-color: #f2f2ff;
      color: #000;
      padding: 20px 0px;
      border-radius: 50px;
      cursor: pointer;
      border-width: 0px;
      font-weight: lighter;
      font-size: 20px;
      &:hover {
        background-color: #e6e6ff;
      }
    }
    .nav-link.active {
      background-color: #d1d1ff;
    }
    .nav-link.disabled {
      color: #aaa;
    }
  }
}

/* Offer block */

.offer-block {
  .list-group-item {
    background-color: #f6fbff;
    margin-top: 1px;
    border-width: 0px;
    border-radius: 0px;
    border-color: #f6fbff;
    &:hover {
      background-color: #edf6ff;
    }
    &:active {
      background-color: #e4f2ff;
    }
    &:first-child {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    input.offer-check {
      border-color: #c4e4ff;
      &:checked {
        background-color: #6ea8fe;
        border-color: #6ea8fe;
      }
      &:focus {
        box-shadow: 0 0 0 0.25rem rgb(98 177 255 / 25%);
      }
    }
  }
}

/* bundle block */

.bundle-block .list-group-item {
  background-color: #fcf9fe;
  margin-top: 1px;
  border-width: 0px;
  border-radius: 0px;
}

.bundle-block .list-group-item-action:hover {
  background-color: #f9f2fc;
}

.bundle-block .list-group-item-action:active {
  background-color: #f6ecfb;
}

input.bundle-check {
  border-color: #e2bff1;
}

input.bundle-check:checked {
  background-color: #cf7ff4;
  border-color: #cf7ff4;
}

input.bundle-check:focus {
  box-shadow: 0 0 0 0.25rem rgb(207 127 244 / 25%);
}

.bundle-block .list-group-item:first-child {
  border-radius: 0px;
}

.bundle-block .list-group-item:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.bundle-block ul li button {
  border-width: 10px;
}

.bundle-block .nav-tabs .nav-item.show .nav-link {
  border-width: 0px !important;
}

.bundle-tabs {
  border-width: 2px !important;
  border-color: #eed9f7 !important;
  margin-bottom: 0px !important;
  margin-top: 5px;
}

.bs-tooltip {
  .tooltip-arrow {
    &::before {
      border-top-color: #f6ecfb;
    }
  }
  .tooltip-inner {
    background-color: #f6ecfb;
    color: #333;
  }
}

.bundle-tabs .nav-item.show .nav-link,
.bundle-tabs .nav-link.active {
  background-color: #eed9f7 !important;
  color: #111 !important;
  font-weight: normal;
  border-width: 0px !important;
  cursor: auto;
}

.bundle-s-all {
  font-size: 24px;
  margin-top: -42px;
  margin-right: 6px;
  cursor: pointer;
  padding: 0px 9px 4px;
  background-color: rgba(0 0 0 / 0%);
  border-radius: 30px;
}

.bundle-s-all svg {
  color: rgba(198 128 227 / 100%);
}

.bundle-s-all:hover {
  background-color: rgba(0 0 0 / 3%);
}

.bundle-s-all:active {
  background-color: rgba(0 0 0 / 5%);
}

.bundle-s-all svg:hover {
  color: rgba(198 128 227 / 80%);
}

.bundle-s-all svg:active {
  color: rgba(198 128 227 / 60%);
}

.cap-title {
  background-color: var(--cap-title-bg-color) !important;
}

.app-tabs {
  border-width: 2px !important;
  border-color: var(--cap-app-title-bg-color) !important;
  margin-bottom: 0px !important;
  margin-top: 5px;
}

.app-tabs .nav-item .nav-link,
.app-tabs .nav-link.active {
  background-color: var(--cap-app-title-bg-color) !important;
  color: #111 !important;
  font-weight: normal;
  border-width: 0px !important;
  cursor: auto;
}

.app-tab .chip-container {
  background-color: var(--cap-app-container-bg-color) !important;
}

.app-tab .chip-container .basic-chip {
  cursor: default;
}

.app-tab .chip-container .background-green {
  background-color: var(--cap-app-container-chip-color) !important;
}

.cap-popover {
  border-color: #d9d9d9;
  .popover-arrow:before {
    border-left-color: #ccc;
  }
  .popover-header {
    background-color: #e5f5f0;
    border-bottom-width: 0px;
  }
  .popover-body {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

/* capability block */

.app-card {
  padding: 16px 0px 0px 0px;
  margin: 0px 0px;
  background-color: #edf8f5 !important;
  border-width: 0px !important;
  border-radius: 0px !important;
}

.app-card .card-body {
  padding: 0px;
}

.app-card .chip-container {
  background-color: #daf1ea !important;
}

.app-card .chip-container .basic-chip {
  background-color: #f6fcfa;
}

.o-d-title {
  letter-spacing: 1.5px;
  font-size: 24px;
  font-weight: lighter;
  margin: 10px 0px 10px 0px;
  border-bottom: 1px solid #eee;
  padding: 0px 0px 5px 0px;
}

/*******************************************
 *            Fill details page            *
 *******************************************/
.d-row {
  background-color: #fff;
  // margin: 0px;
  .o-d-title {
    letter-spacing: 1.5px;
    font-size: 24px;
    font-weight: lighter;
    margin: 10px 0px 10px 0px;
    border-bottom: 1px solid #eee;
    padding: 0px 0px 5px 0px;
  }
  .o-d-title.nb {
    border-bottom: 0px;
  }
  .add-field-opt {
    font-size: 19px;
    color: #666;
    cursor: pointer;
  }
  .add-field-opt.disabled {
    color: #bbb;
    cursor: default;
  }
}

.avbl-check {
  background-color: #f3f8f8;
  padding: 1px 10px;
  font-size: 13px;
  border-radius: 8px;
  color: #426f6f;
  font-weight: 500;
  cursor: pointer;
  &:hover {
    background-color: #e7f1f1;
  }
  &:active {
    background-color: #dae9e9;
  }
}

.avbl-check-spinner {
  color: #426f6f;
  small {
    font-size: 12px;
  }
}

.avbl-check-success {
  color: #28a745;
  svg {
    font-size: 24px;
  }
  small {
    font-size: 12px;
  }
}

.avbl-check-failure {
  color: #dc3545;
  svg {
    font-size: 24px;
  }
  small {
    font-size: 12px;
  }
}

/* collapse design */

// .d-collapse-wrap {
//   background-color: rgba(232 247 255 / 60%);
//   padding: 5px 5px 5px 0px;
//   border-radius: 6px 6px 0px 0px;
//   margin-top: 5px;
// }

// .d-collapse-wrap .c-w-text {
//   padding: 5px 0px 0px 10px;
// }

// .d-collapse-wrap .c-w-text span {
//   font-size: 18px;
//   padding-top: 5px;
// }

// .d-collapse-wrap .c-w-icon {
//   margin-top: 0px;
//   margin-right: 5px;
// }

// .d-collapse-wrap .c-w-icon span {
//   font-size: 24px;
//   cursor: pointer;
// }

.d-accordion {
  background-color: rgba(232 247 255 / 25%);
  padding: 0px !important;
  .accordion-item {
    // background-color: #f2f2ff;
    // border-color: #f2f2ff;
    margin-top: 2px;
    border: none;
    .accordion-header > .accordion-button {
      box-shadow: none;
      background-color: #fff;
      border-bottom: 1px solid #f2f2ff;
      color: #333;
      padding: 8px 10px;
    }
    .accordion-collapse {
      background-color: #fff;
      // border-color: #ceecff;
      .accordion-body {
        padding: 8px 10px;
      }
    }
  }
}

.acc-o-header {
  button {
    background-color: #ebf6ff !important;
  }
}

.acc-o-body {
  /* background-color: rgba(235 246 255 / 40%); */
  background-color: #fff;
}

.acc-b-header button {
  background-color: #fcf9fe !important;
  border-bottom: 0px solid #e2bff1 !important;
}

.acc-b-body {
  /* background-color: #fcf9fe; */
  background-color: #fff;
}

/*******************************************
 *            Review page                 *
 *******************************************/

.r-cards-title {
  font-size: 28px;
  font-weight: normal;
  margin: 0px 0px 0px 0px;
  padding-top: 10px;
}

.r-cards-collapse-all {
  font-size: 22px;
  cursor: pointer;
  background-color: rgba(0 0 0 / 3%);
  padding: 5px 12px 8px 12px;
  border-radius: 6px;
}

.r-cards-collapse-all:hover {
  background-color: rgba(0 0 0 / 5%);
}

.r-cards-collapse-all:active {
  background-color: rgba(0 0 0 / 7%);
}

.r-o-bundles {
  background-color: #fafafa;
  /* min-height: 250px; */
  border-radius: 6px;
  padding: 5px;
}

/* .r-o-bundles>div {
    margin: 0px ;
} */

.o-card {
  background-color: #fff;
  margin: 0px;
  padding: 0px;
  border-width: 0px;
}

.o-card .o-c-header {
  background-color: #fff;
  border-width: 0px;
  border-bottom: 2px solid #f9fdff;
  color: #3b1b00;
  font-weight: 500;
  padding: 10px 16px;
}

.o-card .o-c-body {
  padding: 0px 0px 0px 0px;
}

/* .o-card .o-c-body>div[aria-expanded='false']>div.collapse {
    display: block;
    height: 40px !important;
    overflow: hidden;
}

.o-card .o-c-body>div[aria-expanded='false']>div.collapsing {
    height: 40px !important;
} */

.o-card .o-c-body .o-c-collapse {
  padding: 8px 10px;
  background-color: #fff;
  text-align: left;
  cursor: pointer;
  margin-top: 0px;
  border-top: 2px solid #f9fdff;
}

.o-card .o-c-body .o-c-collapse:hover {
  background-color: rgba(0 0 0 / 2%);
}

.o-card .o-c-body .o-c-collapse:active {
  background-color: rgba(0 0 0 / 3%);
}

.o-card .o-c-body .o-c-collapse .col-text {
  font-size: 14px;
}

.o-card .o-c-body .o-c-collapse .col-icon {
  margin-top: -3px;
}

.o-card .o-c-body .o-c-collapse .col-icon svg {
  font-size: 18px;
}

/* .o-card .o-c-body .collapse {
    padding: 4px 2px;
} */

.o-card .o-c-body .o-bundle-chip {
  display: block;
  background-color: #e8f7ffbf;
  padding: 4px 6px 4px 6px;
  font-weight: normal;
  color: #3b1b00;
  font-size: 14px;
  margin-top: 2px;
}

.r-a-caps {
  background-color: #fafafa;
  /* min-height: 250px; */
  border-radius: 5px;
  padding: 5px;
}

/* .r-a-caps>div {
    padding: 0px 0px;
    margin: 5px 0px 0px 0px;
} */

.a-card {
  background-color: #fff;
  margin: 0px;
  padding: 0px;
  border-width: 0px;
  color: #286a5d;
}

.a-card .a-c-header {
  background-color: #fff;
  border-width: 0px;
  border-bottom: 2px solid #f9fdff;
  color: #3b1b00;
  font-weight: 500;
  padding: 10px 16px;
}

.a-card .a-c-body {
  padding: 0px 0px 0px 0px;
}

/* .a-card .a-c-body>div[aria-expanded='false']>div.collapse {
    display: block;
    height: 40px !important;
    overflow: hidden;
}

.a-card .a-c-body>div[aria-expanded='false']>div.collapsing {
    height: 40px !important;
} */

.a-card .a-c-body .a-c-collapse {
  margin-top: 0px;
  padding: 8px 10px;
  background-color: #fff;
  text-align: left;
  cursor: pointer;
  border-top: 1px solid #f9fdff;
}

.a-card .a-c-body .a-c-collapse:hover {
  background-color: rgba(0 0 0 / 1%);
}

.a-card .a-c-body .a-c-collapse:active {
  background-color: rgba(0 0 0 / 2%);
}

.a-card .a-c-body .a-c-collapse .col-text {
  font-size: 14px;
}

.a-card .a-c-body .a-c-collapse .col-icon {
  margin-top: -3px;
}

.a-card .a-c-body .a-c-collapse .col-icon svg {
  font-size: 18px;
}

/* .a-card .a-c-body .collapse {
    padding: 4px 2px;
} */

.a-card .a-c-body .inst-w {
  background-color: #fff;
  padding: 6px 8px 6px 8px;
  margin: 0px 0px 0px 0px;
  font-size: 14px;
  border-bottom: 1px solid #f9fdff;
}

.a-card .a-c-body .inst-w .inst-t {
  font-weight: bold;
}

.a-card .a-c-body .inst-w .inst-v {
  font-weight: 400;
  width: 65%;
  text-align: right;
}

.a-card .a-c-body .o-cap-chip {
  display: block;
  background-color: #e8f7ff;
  padding: 2px 6px 3px 6px;
  font-weight: normal;
  color: #3b1b00;
  font-size: 14px;
  margin-top: 2px;
}

.a-card .a-c-body .o-cap-chip:first-of-type {
  margin-top: 5px;
}

.a-card .a-c-body .o-cap-chip:last-of-type {
  margin-bottom: 5px;
}

.r-o-caps .c-caps {
  padding: 0px 0px 0px 3px;
}

.r-o-caps .c-caps ul.app-tab {
  margin-top: 8px;
}

.r-o-caps .c-caps ul.app-tab li.nav-item .nav-link {
  background-color: #d83a00;
  color: #ffe2d8;
  border-color: #d83a00;
  font-weight: bold;
}

.r-o-caps .c-caps .tab-content {
  border-top: 2px solid #d83a00;
  margin-top: 49px;
  border-radius: 0px 0px 5px 5px;
  background-color: #ffd4c4;
}

.r-o-caps .c-caps .tab-content .tab-pane .app-caps {
  background-color: #ffd4c4;
  padding: 6px 6px;
  border-radius: 0px 0px 6px 6px;
  min-height: 50px;
}

.r-o-caps .c-caps .tab-content .tab-pane .app-caps .a-cap-chip {
  background-color: #fff1ee;
  padding: 4px 12px 6px 12px;
  font-weight: 400;
  color: #762000;
}

.r-d-cont {
  padding: 0px !important;
}

.r-d-row {
  margin: 0px !important;
}

.r-d-col-l {
  padding: 0px 3px 0px 0px !important;
}

.r-d-col-r {
  padding: 0px 0px 0px 3px !important;
  margin-left: 0px;
}

.r-d-col-title {
  font-size: 22px;
  padding: 10px 0px 12px 0px;
  background-color: #f2f2ff;
  border-radius: 50px;
  margin-bottom: 1px;
}

.r-d-col-l-w,
.r-d-col-r-w {
  padding-left: 0px;
  margin-bottom: 0px;
}

.r-d-col-l .r-d-col-l-w-li,
.r-d-col-r .r-d-col-l-w-li {
  display: block;
  padding: 10px 10px 10px 10px;
  background-color: #f9fdff;
  color: #333;
  margin: 2px 0px;
  border-radius: 0px;
}

.r-d-col-l .r-d-col-l-w-li.mod,
.r-d-col-r .r-d-col-l-w-li.mod {
  background-color: #fbe9ea;
}

.r-d-col-l-w > *:first-child,
.r-d-col-r-w > *:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.r-d-col-l-w > *:last-child,
.r-d-col-r-w > *:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.r-d-col-l-w-li .k {
  font-weight: normal;
}

.r-d-col-l-w-li .v {
  padding: 4px 10px 4px 15px;
  background-color: rgba(232 247 255 / 60%);
  margin: -2px 10px 0px 0px;
  border-radius: 40px;
  float: right;
}

.r-accordion {
  background-color: rgba(232 247 255 / 25%);
  padding: 0px !important;
}

.r-accordion .accordion-item {
  background-color: #f2f2ff;
  border-color: #f2f2ff;
  margin-top: 2px;
}

.r-accordion .accordion-item .accordion-button {
  padding: 10px 12px !important;
}

.r-accordion .accordion-item .accordion-header .accordion-button {
  box-shadow: none;
  background-color: #f2f2ff;
  border-bottom: 1px solid #f2f2ff;
  color: #333;
}

.r-accordion .accordion-item .accordion-collapse {
  background-color: #fbfeff;
  border-color: #ceecff;
}

.r-accordion .accordion-item .accordion-collapse .accordion-body {
  padding: 2px 5px;
}

.r-accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  .r-d-col-l-w-li {
  padding: 5px 10px 5px 15px;
}

.r-accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  .r-d-col-l-w-li.deep {
  padding: 5px 20px 5px 25px;
}

.r-accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  .r-d-col-l-w-li
  .v {
  padding: 2px 5px 2px 5px;
}

.r-accordion
  .accordion-item
  .accordion-collapse
  .accordion-body
  .accordion-button {
  padding: 10px 12px !important;
}

/* Profile select button */

.profile-button {
  text-align: center !important;
  cursor: pointer !important;
  padding: 15px 45px !important;
  border-radius: 30px !important;
  background-color: #f5f5f5 !important;
  border-color: #f5f5f5 !important;
  box-shadow: none !important;
  font-size: 16px !important;
  color: #333 !important;
  &:hover {
    background-color: #e6e6ff !important;
    border-color: #e6e6ff !important;
  }
  &:active {
    background-color: #d1d1ff !important;
    border-color: #d1d1ff !important;
  }
}

/* Chip style here - move to appropriate section */

.chip-container {
  padding: 10px;
  margin: 0px;
  border-radius: 0px;
  background-color: #edf8f5;
  .basic-chip a {
    color: inherit;
  }
}

.chip-title {
  padding: 20px 10px;
  font-size: 14px;
}

.basic-chip {
  padding: 2px 10px;
  border-radius: 50px;
  display: inline-flex;
  margin: 3px 4px;
  font-weight: 400;
}

.click-chip,
.click-chip-hover {
  padding: 5px 10px;
  border-radius: 50px;
  display: inline-flex;
  margin: 5px;
  cursor: pointer;
  &:hover {
    filter: brightness(85%);
  }
}

.outline {
  border: 1.5px solid #cccccc;
  color: #cccccc;
  outline: none;
}

.outline-green {
  border: 1.5px solid #3a913f;
  color: #3a913f;
  outline: none;
}

.outline-blue {
  border: 1.5px solid #0074c3;
  color: #0074c3;
  outline: none;
}

.background-grey {
  background: #dddddd;
  color: #616161;
}

.background-green {
  background: #fbfefd;
  color: #083225;
}

.background-blue {
  background: #0074c3;
  color: #ffffff;
}

.icon {
  /*background: #bdbdbd;*/
  color: #616161;
  margin: 0px 5px 0px -5px;
  background: #bbbbbb;
  padding: 2px;
  border-radius: 50px;
}

.fa-times,
.fa-times-circle {
  margin: 0px -5px 0px 5px;
}

ul.s-o-tabs {
  background-color: rgba(50 50 0 / 20%);
  padding: 0px 0px 5px 5px;
  li.nav-item {
    margin: 5px 5px 0px 0px;
  }
  button.nav-link {
    background-color: #f5f5f5;
    border-radius: 30px;
    color: #333;
    &:hover {
      background-color: #fff;
    }
  }
  .active {
    background-color: #0d6efd !important;
    color: #fff;
  }
}

.tab-content .tab-pane .tabs-container {
  background-color: #f5f5f5;
  min-height: 150px;
  margin: 0px;
  padding: 0px;
  .row {
    margin: 0px;
    padding: 0px;
    .s-o-t-col {
      min-height: 150px;
      padding: 0px;
    }
    .s-o-t-bundles {
      background-color: rgb(240, 180, 180);
      .nav .nav-item a {
        background-color: #fafafa;
        color: #333;
        margin-bottom: 1px;
        border-radius: 0px;
        &:hover {
          background-color: #f5f5f5;
        }
        &:active {
          background-color: #555;
          color: #fff;
        }
      }
    }
    .s-o-t-bundle-fields {
      background-color: #ff00000d;
      .s-o-wrap {
        p.on {
          display: inline-block;
          background-color: #ff9b0040;
          padding: 5px 10px;
          border-radius: 8px;
          margin-left: 10px;
          color: #9b3700;
          cursor: pointer;
          &:hover {
            background-color: #ff9b0025;
          }
        }
      }
    }
    .s-o-t-apps {
      background-color: #0000ff0d;
      padding: 0px 5px;
    }
  }
}

.s-b-tabs {
  .nav-item {
    a.nav-link {
      background-color: #ff00000d;
      margin: 2px;
      padding: 4px 4px 4px 10px;
      border-radius: 8px;
      border: 1px solid #f004;
      color: #300;
      font-size: 14px;
      span.fc {
        padding: 5px 6px 5px 5px;
        border-radius: 8px !important;
        background-color: #c8000026 !important;
        color: #300;
      }
      &:hover {
        background-color: #ff000014;
      }
    }
    .active {
      background-color: #f004 !important;
      color: #300;
      border-width: 0px !important;
      span.fc {
        background-color: #c8000026 !important;
      }
    }
  }
}

.r-f-row {
  background-color: #fff;
  border-radius: 8px;
  padding: 0px 0px;
  .r-f-col {
    padding: 10px 10px 10px 0px;
    .r-f-title {
      color: #483d8b;
      font-weight: 500;
      // font-size: 14px;
    }
    .r-f-val {
      color: #332b62;
      font-weight: normal;
      // font-size: 16px;
    }
  }
  .r-f-col.mod {
    // background-color: #00c8000a;
    .r-f-title {
      color: #49a656;
    }
    .r-f-val {
      color: #3d8b48;
    }
  }
  .r-f-col.error {
    // background-color: #00c8000a;
    .r-f-title {
      color: rgba(220 53 69 / 100%);
    }
    .r-f-val {
      color: rgba(220 53 69 / 100%);
    }
  }
}

.sub-view {
  background-color: #fff;
  p.view-title {
    font-weight: lighter;
    font-size: 24px;
    letter-spacing: 1.5px;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
  }
  .title {
    margin: 0px;
    padding: 3px 0px;
    font-size: 18px;
    font-weight: 500;
    color: #5649a6;
  }
  .sub-view-col {
    background-color: #fff;
    padding: 5px 0px 5px 0px;
    .cont-area {
      margin-bottom: 10px;
      .cid {
        background-color: #f6f7ff;
        display: inline-block;
        border-radius: 8px;
        color: #0b0b59cc;
        padding: 4px 10px;
        cursor: pointer;
        border: 1px solid #cfd4ff;
        margin-right: 5px;
        &:hover {
          background-color: #e2e6ff80;
        }
      }
      .cid.active {
        background-color: #e2e6ff;
      }
    }
    .o-b-area {
      padding: 5px 0px;
      .offer {
        background-color: #f6fbff;
        display: inline-block;
        border-radius: 8px;
        color: #37737d;
        padding: 2px 8px;
        cursor: pointer;
        border: 1px solid #bbe1ff;
        font-size: 15px;
        &:hover {
          background-color: #e2f2ff80;
        }
        span {
          padding: 4px 8px;
          margin: 0px 5px 0px -8px;
          border-radius: 8px 0px 0px 8px;
          font-size: 14px;
          color: #fff;
        }
        span.new {
          background-color: #1fb177;
        }
        span.mod {
          background-color: #f3f392;
          color: #000;
        }
      }
      .offer.active {
        background-color: #cfeaffbf;
      }
      .bundles {
        padding: 5px 0px 10px 0px;
        margin-bottom: 5px;
        .b-chip {
          padding: 0px 0px 0px 6px;
          margin: 5px 5px 0px 0px;
          background-color: #fcf9fe;
          border: 1px solid #ebd7f8;
          border-radius: 30px;
          cursor: pointer;
          font-size: 14px;
          color: #6c1ba2;
          max-width: 75%;
          &:hover {
            background-color: #f4e8fb80;
          }
          .b-state {
            padding: 2px 8px;
            background-color: #1fb177;
            margin: 0px 5px 0px -6px;
            border-radius: 30px 0px 0px 30px;
            color: #fff;
          }
          .b-name {
            display: inline-block;
          }
          .b-count {
            display: inline-block;
            background-color: #ebd7f8;
            padding: 2px 8px;
            margin-left: 10px;
            border-radius: 0px 30px 30px 0px;
          }
        }
        .b-chip.count {
          padding: 0px;
          .b-name {
            padding: 2px 8px;
          }
        }
        .b-chip.active {
          background-color: #f4e8fb;
          &:hover {
            background-color: #f4e8fb;
          }
        }
      }
    }
    .o-b-r-area {
      padding: 5px 0px;
      .offer {
        background-color: #fff6ed;
        display: inline-block;
        border-radius: 8px;
        color: #643200;
        padding: 4px 8px;
        border: 1px solid #ffe3c6;
        font-size: 15px;
      }
      .bundles {
        padding: 5px 0px 10px 0px;
        margin-bottom: 5px;
        .b-chip {
          padding: 0px 0px 0px 10px;
          margin: 5px 5px 0px 0px;
          background-color: #ffeded;
          border: 1px solid #ffd9d9;
          border-radius: 30px;
          font-size: 14px;
          color: #640000;
          .b-name {
            display: inline-block;
          }
          .b-count {
            display: inline-block;
            background-color: #ffd9d9;
            padding: 2px 5px;
            margin-left: 10px;
            border-radius: 0px 30px 30px 0px;
          }
        }
      }
    }
    .b-v-area {
      padding: 5px 0px;
      .b-val-cont {
        padding: 0px;
        .b-val-row {
          padding: 0px;
          .b-val-col {
            margin: 0px 0px 15px 0px;
            padding-left: 15px;
            .b-val-title {
              font-size: 16px;
              font-weight: 500;
              color: #483d8b;
            }
            .b-val {
              font-size: 16px;
              color: #332b62;
            }
          }
          .b-val-col.mod {
            .b-val-title {
              color: #49a656;
            }
            .b-val {
              color: #3d8b48;
            }
          }
        }
      }
    }
    .b-i-area {
      padding: 5px 0px;
      .b-i-cont {
        padding: 0px;
        .b-i-row {
          margin: 0px 0px 10px 0px;
          padding-right: 5px;
          .b-i-col {
            padding: 0px;
            .k {
              font-weight: 500;
            }
            .v {
              padding-left: 5px;
            }
          }
        }
      }
    }
    .a-c-area {
      padding: 5px 0px;
      .a-card {
        background-color: #f7fcfb25;
        padding: 0px;
        border-radius: 8px;
        margin-bottom: 8px;
        border: 1px solid #dbf1ed;
        font-size: 14px;
        .app-title {
          padding: 5px 10px;
          margin-bottom: 5px;
          background-color: #dbf1ed75;
          border-radius: 8px 8px 0px 0px;
          color: #286a5d;
          .app-name {
            // font-size: 15px;
            font-weight: bold;
            width: 60%;
            .app-name-icon {
              font-size: 19px;
              margin-top: -4px;
              margin-right: 2px;
            }
            .app-name-type {
              font-weight: 100;
              font-style: italic;
              font-size: 13px;
              margin-left: 3px;
            }
            .app-instance-small {
              font-size: 13px;
              font-weight: 400;
            }
          }
          .app-dc {
            // font-size: 14px;
            padding-top: 2px;
            width: 40%;
          }
        }
        .cap-heading {
          padding: 5px 10px 0px;
          color: #286a5d;
          font-weight: 500;
        }
        .app-caps {
          padding: 5px 10px;
          .app-cap {
            margin-bottom: 5px;
            margin-right: 5px;
            background-color: #e9f7f4bf;
            border-radius: 30px;
            padding: 2px 8px;
            font-size: 13px;
            color: #286a5d;
            cursor: default;
            box-shadow: 0px 0px 2px rgba(40 106 93 / 50%);
          }
        }
      }
    }
  }
}

.uo-area {
  margin: 10px 0px 20px 0px;
  .search-bar {
    padding-left: 0px;
    .text {
      input.form-control {
        border-radius: 50px 0 0 50px;
        font-weight: normal;
        padding-left: 25px;
        &:focus {
          border-color: #d1d6dc;
          box-shadow: 0 0 0 0.25rem rgb(220 224 229 / 50%);
        }
      }
      label {
        padding-left: 25px;
      }
    }
    .sbtn {
      .btn {
        border-radius: 0 50px 50px 0;
        padding: 12px 0px;
        span.i {
          padding: 0px 1.5rem;
          svg {
            font-size: 32px;
          }
        }
      }
    }
  }
  .u-opts {
    padding: 0px 0px 0px 0px;
    .pl {
      margin: 0px 0px;
    }
  }
}

.uo-area.es {
  margin: 5px 0px 10px 0px;
}

.uo-area.cs {
  margin: 5px 0px 10px 0px;
}

.mod-value-tooltip {
  .tooltip-inner {
    background-color: #fefefe;
    color: #332b62;
    border: 1px solid #3d8b4840;
  }
  .tooltip-arrow {
    &::before {
      border-top-color: #3d8b4880;
    }
  }
}

.modal-90w {
  width: 85%;
  max-width: 85%;
}

.pld-container {
  .pld-col-heading {
    padding: 5px 5px;
    background-color: #fafafa;
    color: #000;
    letter-spacing: 1.5px;
    font-weight: lighter;
    font-size: 22px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    margin-bottom: -1px;
  }
  .pld-json {
    padding: 10px 5px;
  }
  .pld-offer {
    background-color: #f6fbff;
    padding: 7px 0px 7px 5px;
    border-top: 1px solid #bbe1ff;
    border-bottom: 1px solid #bbe1ff;
    font-size: 16px;
    color: #37737d;
    font-weight: 500;
  }
  .pld-bundle {
    background-color: #fcf9fe;
    padding: 5px 0px 5px 15px;
    border-top: 1px solid #ebd7f8;
    border-bottom: 1px solid #ebd7f8;
    font-size: 15px;
    color: #6c1ba2;
    font-weight: 500;
    margin-top: -1px;
  }
  .pld-diff {
    padding: 5px 25px;
    .pld-diff-viewer {
      font-size: 14px;
    }
  }
  .pld-diff.add {
    background-color: rgba(0 255 0 / 10%);
    td.line-add {
      background-color: transparent !important;
    }
    td.line-remove {
      background-color: transparent !important;
    }
  }
  .pld-diff.rem {
    background-color: rgba(255 0 0 / 10%);
    td.line-add {
      background-color: transparent !important;
    }
    td.line-remove {
      background-color: transparent !important;
    }
  }
}

a.disabled {
  pointer-events: none;
}

.top-content {
  margin-left: 0px;
  .sid {
    margin: 15px 0px;
    font-size: 20px;
    color: #663399;
    .v {
      font-weight: 500;
    }
  }
  .cname {
    margin: 15px 0px;
    font-size: 24px;
    color: #663399;
    // font-weight: lighter;
    // letter-spacing: 1.25px;
  }
}

.trial-check {
  .form-check-input {
    &:checked {
      background-color: #6c757d;
      border-color: #6c757d;
    }
    &:hover {
      border-color: #565e64;
    }
    &:active {
      background-color: #565e64;
      border-color: #51585e;
    }
    &:focus {
      border-color: #51585e;
      box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.125);
    }
  }
}

.add-user-btn {
  padding: 4px 8px;
  background-color: #1a6985;
  cursor: pointer;
  color: #fff;
  border-radius: 6px;
  .add-user-btn-icon {
    font-size: 17px;
    color: #fff;
    padding-right: 3px;
    margin-top: -2px;
  }
  .text {
    font-size: 14px;
  }
  &:hover {
    background-color: #2083a6;
  }
  &:active {
    background-color: #144f64;
  }
}
