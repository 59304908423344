.app-info-wrap {
  margin: 0px 7px;
  padding: 5px;
  border-radius: 8px;
  color: #286a5d;
  .app-info {
    padding: 3px 0px;
    // font-size: 14px;
    .k {
      padding-left: 5px;
      text-align: left;
      display: inline-block;
    }
    .v {
      padding-right: 10px;
      text-align: right;
      display: inline-block;
    }
  }
  .app-info-collapse {
    cursor: pointer;
    font-size: 14px;
    margin-top: 5px;
    svg {
      margin-top: -2px;
      font-size: 12px;
    }
  }
}

.app-dc.SUCCESS {
  color: #198754;
}
.app-dc.IN_PROGRESS {
  color: #873e01;
}
.app-dc.FAIL {
  color: #74141d;
}
